import React, {FC, useEffect, useState} from "react"
import {useSelector} from "react-redux";
import {IState} from "../../../types/state";
import {ArrowRoundedDown12x7Svg} from '../../../svg'
import {ITopBarProps as IProps} from "./types";
import {useRouter} from "next/router";
import cls from "../header.module.scss"
import {domainUrl} from "../../../helper";
import Image from "next/image";

const TopBarDropDownBtn: FC<IProps> = ({
                                           classname,
                                           currency,
                                           handleButtonClick,
                                           local,
                                           newLocal,
                                           open,
                                           current,
                                           currencyList,
                                           itemsLength,
                                           pageProps
                                       }) => {
    const router = useRouter();
    const [languageName, setLanguageName] = useState<string | undefined>(pageProps && pageProps.topBarLocales?.[0].name);
    const domain = useSelector((state: IState) => state.general.domain);
    // const dbName = useSelector((state: IState) => state.general.dbName);
    const locale = useSelector((state: IState) => state.locale)
    const curr = currencyList?.find((item: {
        code?: string
    }) => item?.code == current);
    const dbName = pageProps?.dbName || process.env.domainName

    const diffProductImage = local && local.locale_image
        ? `${dbName}/storage/${pageProps?.domain || domain}/${local.locale_image}`
        : `${dbName}/vendor/webkul/ui/assets/images/flag_${pageProps?.locale}.svg`

    useEffect(() => {
        setLanguageName(
            // @ts-ignore
            router.asPath === "/"
                ? (
                    pageProps
                    && pageProps.topBarLocales
                        ?.find((item: {
                            code?: string
                        }) => item.code === newLocal)?.name
                )
                : locale.list?.find((item: {
                    code?: string
                }) => item.code === newLocal)?.name
        )
    }, [newLocal])

    const renderImage = () => {
        if (currency) {
            return (
                <div className={cls["lang-mobile-img_level_1"]}>
                    {curr?.symbol}
                </div>
            );
        } else {
            return (
                <div style={{display: "flex"}}>
                    <Image
                        src={domainUrl(diffProductImage)}
                        alt={`${local && local.name} language`}
                        height={16}
                        width={20}
                    />
                </div>
            );
        }
    };
    return (
        <button
            className={`${cls["topBar-dropdown__btn"]} ${classname}`}
            type="button"
            onClick={handleButtonClick}
        >
            <div className={cls["lang-name-image"]}>
                <p className={`${cls["lang-name"]} ${currency}`}>
                    {(currency && curr?.name) || languageName}
                </p>
                {renderImage()}
            </div>
            {
                itemsLength && itemsLength > 1
                && <ArrowRoundedDown12x7Svg
                    className={open && `${cls["rotT_fms"]}`}
                />
            }
        </button>
    )
}

export default TopBarDropDownBtn;