import React, {FC, memo} from "react"
import {url} from "../../../services/utils";
import {IRenderLink as IProps} from "./types";
import cls from '../header.module.scss'
import Link from "next/link";

const RenderLink: FC<IProps> = ({item, content, onClick, onCloseWindow}) => {
    if (item.slug) {
        return (
            <Link href={url.category(item)}>
                <a
                    className={cls.menu__li__a}
                    {...item.props}

                    onClick={() => {
                        onClick(item)
                        onCloseWindow && onCloseWindow()
                    }}
                >
                    {content}
                </a>
            </Link>
        )
    }
    return (
        <a
            className={cls.menu__li__a}
            href={`/${item.code}`}
            onClick={(e) => {
                e.preventDefault()
                onClick(item)
                onCloseWindow && onCloseWindow()
            }}
        >
            {content}
        </a>
    );
};

export default memo(RenderLink);
