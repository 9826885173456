import React, {FC} from "react";
import {useSelector} from "react-redux";
import {IRenderIcon as IProps} from "./types";
import cls from "../header.module.scss"
import {IState} from "../../../types/state";
import {domainUrl} from "../../../helper";
import Image from "next/image";

const RenderIcon: FC<IProps> = ({item, withIcons}) => {
    const domain = useSelector((state: IState) => state.general.domain);
    const dbName = useSelector((state: IState) => state.general.dbName);

    const diffProductImage = item && item.locale_image
        ? `${dbName}/storage/${domain}/${item.locale_image}`
        : `${dbName}/vendor/webkul/ui/assets/images/flag_${item.code}.svg`

    if (withIcons) {
        return (
            <div className={cls.menu__icon}>
                <Image
                    src={domainUrl(diffProductImage)}
                    alt={`${item && item.name} language`}
                    // placeholder="blur"
                    height={16}
                    width={20}
                    // blurDataURL={`/zega/blur1.webp`}
                />
            </div>
        );
    }

    return null;
}
export default RenderIcon