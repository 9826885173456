import React, {FC, memo} from "react";
import Menu from "../Menu";
import {IRenderSubMenu as IProps} from "./types";
import cls from "../header.module.scss";

const RenderSubMenu: FC<IProps> = ({item, onClick}) => {
    if (item.children && item.children.length > 0) {
        return (
            <div className={cls.menu__submenu}>
                <Menu items={item.children} onClick={onClick} />
            </div>
        );
    }

    return null;
}

export default memo(RenderSubMenu);
