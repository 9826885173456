import React, {FC} from "react";
import classNames from "classnames";
import {IMenuType as IProps} from "../Menu/types";
import RenderMenuItem from "../Menu/RenderMenuItem";
import cls from "../header.module.scss"

const Menu: FC<IProps> = ({
                              layout,
                              withIcons,
                              items,
                              onClick,
                              onCloseWindow
                          }) => {

    const classes = classNames(`${cls.menu} ${cls[`menu--layout--${layout}`]}`,
        withIcons && cls["menu--with-icons"]
    );

    return <ul className={classes}>{items?.map((item, index) => (
        <RenderMenuItem
            key={index}
            item={item}
            index={index}
            withIcons={withIcons}
            onClick={onClick}
            onCloseWindow={onCloseWindow}
        />
    ))}</ul>;
}

export default Menu
